<template>
  <div class="help">
    <div class="top-banner">
      <div class="title container">
        <h1>帮助中心</h1>
        <h2>QUESTIONS  & ANSWERS</h2>
      </div>
    </div>

    <div class="content container space">
      <div class="common-middle-title">
        <h1>常见问题</h1>
        <h3>· · ·</h3>
      </div>

      <div class="ques-list">
        <div class="ques-item" v-for="(item, i) in quesList" :key="i">
          <div class="ques-title">
            <img src="../assets/help/wh.png" alt="">
            {{item.title}}
          </div>
            <div class="ques-line">
              <span class="line-pink"></span>
              <span class="line-gery"></span>
            </div>
          <div class="ask">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data(){
    return {
      quesList: [
        {
          title: '如何获取BFS商品及品牌相关最新资讯？',
          content: '下载BFS APP，即可查看品牌最新动态'
        },
        {
          title: '如何注册登录BFS APP?',
          content: `输入手机号（国内外手机号皆可注册）验证，然后向自己的邀请人索要优惠码即可注册成功。 
                    根据提示提交个人相关信息，确保信息被录入系统之后，可根据注册时的手机号来获取动态验证码直接登录BFS APP， 
                    关联微信后可一键登陆（PS：BFS严格遵守用户隐私协议，可放心使用）。`
        },
        {
          title: 'BFS如何为创业者保驾护航？',
          content: `平台从全球臻选优质护肤品牌，首先在品质上让您远离后顾之忧。其次，通过整合全球顶级研发资源，深度优化产业链，
                  为您提供商品、物流、培训、售后等一站式全套服务。更有医美院线资深经营管理专家，全方位政策扶持，助您轻松创业，持久盈利。`
        },
        {
          title: '平台产品如何做到正品保证？',
          content: 'BFS平台产品由品牌直接授权，含报关证明，通过跨境仓检验，经过海关监管发货，确保正品，接受随时检验。'
        },
        {
          title: '如何与BFS取得联系？',
          content: '您可拨打BFS服务热线400-889-3228进行咨询，也可微信搜索“BFSBESR”公众号或在APP上进行在线咨询服务。'
        },
        {
          title: '平台产品都是最低价吗？',
          content: '平台产品都是正规纳税，价格合理，基本可以保证平台会员购买价格为最低零售价。'
        },
        {
          title: '平台产品发货快吗？包邮吗？',
          content: '非预售产品基本可以保证48小时内发货，除海外自提产品外所有产品包邮。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  .content {
    box-shadow:0px 2px 40px 0px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: aut;
    padding: 60px 0;

    .ques-list {
      width: 850px;
      max-width: 90%;
      margin-top: 50px;
      .ques-item {
        margin-bottom: 30px;
      }
      .ques-title {
        font-size: 20px;
        color: #444;
        position: relative;
        font-weight: bold;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      
      .ques-line {
        width: 100%;
        display: flex;
        margin: 10px 0;
        .line-pink {
          width:160px;
          height:1px;
          background:#f31d50;
          margin-right: 10px;
        }
        .line-gery {
          flex: 1;
          height:1px;
          background:#333
        }
      }
      .ask {
        min-height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding-left: 25px;
        font-size: 14px;
      }
      .ask:before {
        content: 'A';
        width:16px;
        height: 100%;
        background-color: #f31d50;
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }
}
@media (max-width: 768px){
  .help {
    .content {
      padding: 20px 0;
      margin: 20px auto;
      .ques-list {
        margin-top: 15px;
        .ques-item {
          .ques-title {
            font-size: 14px;
          }
          .ques-line .line-pink {
            width: 75px;
          }
          .ask {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>